<template>
  <div class="record">
    <van-empty
      v-if="data.length == 0"
      image="https://cdn.qiumo.net.cn/allsmarketmaterial/woMusicRing/empty.png"
      description="暂无领取记录"
    />
    <template v-else>
      <div class="box" v-for="(item, index) in data" :key="index">
        <div class="line">
          <div class="label">领取权益</div>
          <div class="value name">{{ item.equityName }}</div>
        </div>
        <div class="line">
          <div class="label">领取号码</div>
          <div class="value">{{ item.phone }}</div>
        </div>
        <div class="line">
          <div class="label">领取时间</div>
          <div class="value">{{ item.createTime }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getRecord } from "@/api/changXueSupreme/index.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapGetters(["changXueSupreme_token", "changXueSupreme_productNo"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });
      try {
        const { data = [] } = await getRecord(
          this.changXueSupreme_token,
          this.changXueSupreme_productNo
        );
        this.data = data;
      } finally {
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  padding: 40px 30px 100px;
  box-sizing: border-box;
  height: 100%;
  background: #f6f6f6;
  overflow-y: auto;

  .box {
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 30px;
    margin-bottom: 30px;

    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        padding-bottom: 20px;
      }

      .label {
        font-weight: 400;
        font-size: 26px;
        color: #999999;
      }

      .value {
        font-weight: 400;
        font-size: 26px;
        color: #333333;
      }

      .name {
        font-weight: 500;
        font-size: 30px;
      }
    }
  }
}
</style>

<style  lang="scss">
.record {
  .van-empty {
    padding-top: 64px;
    .van-empty__image {
      width: 460px;
      height: auto;
    }
  }
}
</style>
